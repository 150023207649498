import React, { useEffect } from "react";
import { useAuth } from "../store/AuthContext";
import { fetchLogin } from "../api/api";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";

function Signin({ history }) {
  const { qrcode } = useParams();
  const { login } = useAuth();

  useEffect(() => {
    (async () => {
      const loginResponse = await fetchLogin({
        qrcode,
      });

      login(loginResponse);
      history.push("/");
    })();
  }, [history, login, qrcode]);

  return <Loading height="100vh" />;
}

export default Signin;
