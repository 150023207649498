import { Box, Button, Grid, GridItem, Image, useTheme } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DefaultProductImage from "../assets/DefaultProductImage.jpg";
import { useSettings } from "../store/SettingsContext";
const ProductCard = ({ item, addBasketAll }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getLangText } = useSettings();

  const gridTemplateRows = item?.description?.TRTR
    ? "repeat(3, 1fr)"
    : "repeat(2, 1fr)";

  // Geçici bir çözüm bu, ileride bu yapıyı gridden kesnlikle çıkarıcaz.
  return (
    <Fragment>
      <Link key={item.id} to={`/product_detail/${item.id}`}>
        <Box mb={2} bgColor="white.100" borderRadius={12}>
          <Grid
            h="100%"
            width="100%"
            bgColor="white"
            templateRows={gridTemplateRows}
            templateColumns="repeat(5, 1fr)"
            gap={1}
            borderRadius={12}
            boxShadow="2xl"
          >
            {item.image !== null ? (
              <GridItem rowSpan={3} colSpan={1}>
                <Box width="6rem" height="6rem">
                  <Image
                    mt={4}
                    p={1}
                    borderRadius={20}
                    boxSize="6rem"
                    src={`${process.env.REACT_APP_BASE_ENDPOINT}/storage/${item.image}`}
                    alt={getLangText(item.title)}
                    loading="lazy"
                  />
                </Box>
              </GridItem>
            ) : (
              <GridItem rowSpan={3} colSpan={1}>
                <Box width="6rem" height="6rem">
                  <Image
                    mt={4}
                    p={1}
                    borderRadius={20}
                    boxSize="6rem"
                    src={DefaultProductImage}
                    alt={getLangText(item.title)}
                    loading="lazy"
                  />
                </Box>
              </GridItem>
            )}

            <GridItem colSpan={4}>
              <Box
                mt={5}
                fontSize={{ base: "20px", lg: "30px" }}
                fontWeight="bold"
                as="h1"
                lineHeight="tight"
              >
                {getLangText(item.title)}
              </Box>
            </GridItem>
            {item.description?.TRTR && (
              <GridItem colSpan={4}>
                <Box fontSize={10} color="gray" alignItems="baseline">
                  {getLangText(item.description)}
                </Box>
              </GridItem>
            )}

            <GridItem display="flex" alignItems="center" colSpan={2}>
              <Box
                style={{ marginLeft: 0 }}
                mr={3}
                borderRadius={5}
                border="3px solid teal"
                mx="auto"
                width="100px"
                textAlign="center"
                color="teal"
                fontSize={16}
                fontWeight="bold"
              >
                {item.price} ₺
              </Box>
            </GridItem>
            <GridItem colSpan={2} position="relative">
              {getLangText(item.description) === null &&
              getLangText(item.allergen) === null &&
              item.quantity === null &&
              getLangText(item.removed_material) === null ? (
                <Fragment>
                  {item.status ? (
                    <Button
                      onClick={(e) => addBasketAll(e, item)}
                      mr={3}
                      right="0"
                      bottom="2"
                      borderRadius={10}
                      position="absolute"
                      border="1px solid teal"
                      textAlign="center"
                      color="white"
                      bgGradient={theme.colors.ithinkGradient}
                      _hover={{ bgColor: theme.colors.ithinkGradient }}
                      background={theme.colors.ithinkGradient}
                      fontSize={16}
                      fontWeight="bold"
                    >
                      {t("Sepete Ekle")}
                    </Button>
                  ) : (
                    <Button
                      isDisabled={true}
                      mr={3}
                      right="0"
                      bottom="2"
                      borderRadius={10}
                      position="absolute"
                      border="1px solid teal"
                      textAlign="center"
                      color="white"
                      bgGradient={theme.colors.ithinkGradient}
                      _hover={{ bgColor: theme.colors.ithinkGradient }}
                      background={theme.colors.ithinkGradient}
                      fontSize={16}
                      fontWeight="bold"
                    >
                      {t("Stokta Yok")}
                    </Button>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {item.status ? (
                    <Button
                      mr={3}
                      right="0"
                      bottom="2"
                      borderRadius={10}
                      position="absolute"
                      border="1px solid teal"
                      textAlign="center"
                      color="white"
                      bgGradient={theme.colors.ithinkGradient}
                      _hover={{ bgColor: theme.colors.ithinkGradient }}
                      background={theme.colors.ithinkGradient}
                      fontSize={16}
                      fontWeight="bold"
                    >
                      {t("Detayını Gör")}
                    </Button>
                  ) : (
                    <Button
                      isDisabled={true}
                      mr={3}
                      right="0"
                      bottom="2"
                      borderRadius={10}
                      position="absolute"
                      border="1px solid teal"
                      textAlign="center"
                      color="white"
                      bgGradient={theme.colors.ithinkGradient}
                      _hover={{ bgColor: theme.colors.ithinkGradient }}
                      background={theme.colors.ithinkGradient}
                      fontSize={16}
                      fontWeight="bold"
                    >
                      Stokta Yok
                    </Button>
                  )}
                </Fragment>
              )}
            </GridItem>
          </Grid>
        </Box>
      </Link>
    </Fragment>
  );
};

export default ProductCard;
