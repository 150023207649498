import React, { useEffect, useState } from "react";
import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Alert,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useTheme,
} from "@chakra-ui/react";
import NavbarBottom from "../layout/NavbarBottom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { accoundShow, accountRequest } from "../api/api";
import Navbar from "../layout/Navbar";
import Loading from "../components/Loading";
import { useSettings } from "../store/SettingsContext";

function Account() {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pay, setPay] = useState([]);
  const [tip, setTip] = useState("0");
  const { t } = useTranslation();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState();
  const { restaurantName } = useSettings();

  const { getLangText } = useSettings();
  const handleCheckboxChange = (e) => {
    e.preventDefault();
    const { value, checked } = e.target;
    if (checked) {
      setPay((pre) => [...pre, value]);
    } else {
      setPay((pre) => {
        return [...pre.filter((material) => material !== value)];
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const data = await accoundShow();
        setProducts(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const total = products
    ?.reduce(
      (total, product) => (total = total + product.price * product.quantity),
      0
    )
    .toFixed(2);

  const hesapToplam = parseFloat(total) + parseFloat(tip);

  const accountPost = async () => {
    const data = {
      tip,
      pay: pay.toString(),
    };

    await accountRequest(data);
    setPay([]);
    setTip("0");
  };

  if (loading) {
    return <Loading height="100vh" />;
  }

  return (
    <div>
      <Box>
        <Navbar />
        <Link to="/basket">
          <Flex>
            <Box position="absolute" top="2" ml={2}>
              <i className="fas fa-angle-left yön"></i>
            </Box>
          </Flex>
        </Link>
        <Box mt={5} mb={5} textAlign="center">
          <Text color="teal.500" fontSize={30} fontWeight="semibold">
            {t("Hesap Talebi")}
          </Text>
        </Box>
        <Box mb={150}>
          {products?.length < 1 && (
            <Container bgColor="white">
              <Alert status="warning">{t("Ürün Bulunmuyor")}</Alert>
            </Container>
          )}
          {products?.length > 0 && (
            <>
              {products?.map((product, i) => {
                return (
                  <Box key={i} mb={3} bgColor="white">
                    <Grid
                      h="100%"
                      bgColor="white"
                      gridTemplateColumns="repeat(5, 1fr)"
                      gap={1}
                      borderRadius={12}
                      boxShadow="2xl"
                    >
                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box fontSize={16} color="teal" fontWeight="bold">
                          {getLangText(product.title)}
                        </Box>
                      </GridItem>

                      <GridItem w="100%" colSpan={1} mt={1} p={2}>
                        <Box
                          border="1px"
                          borderRadius={8}
                          color="teal"
                          textAlign="center"
                          fontSize={16}
                        >
                          <Text paddingX={0} color="teal">
                            x<strong> {product.quantity}</strong>
                          </Text>
                        </Box>
                      </GridItem>

                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box
                          borderRadius={10}
                          border="1px solid teal"
                          mx="auto"
                          width="100px"
                          textAlign="center"
                          color="teal"
                          fontSize={16}
                          fontWeight="bold"
                        >
                          {(product.price * product.quantity).toFixed(2)} ₺
                        </Box>
                      </GridItem>
                    </Grid>
                  </Box>
                );
              })}
            </>
          )}
        </Box>

        <Box
          position="fixed"
          bottom="12"
          width="100%"
          bgColor="white"
          boxShadow="lg"
          p={3}
        >
          <Container>
            <Button
              disabled={products?.length < 1}
              onClick={onOpen}
              color="white"
              height="50px"
              width="65%"
              bgGradient={theme.colors.ithinkGradient}
              _hover={{ bgColor: theme.colors.ithinkGradient }}
              background={theme.colors.ithinkGradient}
            >
              {t("Hesap Talep Et")}
            </Button>
            <Modal onClose={onClose} isOpen={isOpen} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader color="teal">
                  {restaurantName && restaurantName}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody color="gray">
                  <Flex mb={3}>
                    <Text color="gray" fontWeight="semibold">
                      {" "}
                      {t("Hesap Tutarı :")}
                    </Text>
                    <Text ml={2} color="teal">
                      {hesapToplam} ₺
                    </Text>
                  </Flex>
                  <Flex mb={3}>
                    <Text color="gray" fontWeight="semibold">
                      {" "}
                      {t("Ödeme Şekli :")}
                    </Text>
                    {pay.map((item, i) => (
                      <Text key={i} ml={2} color="teal">
                        {item}
                      </Text>
                    ))}
                  </Flex>
                  <Flex mb={5}>
                    <Text alignSelf="center" color="gray" fontWeight="semibold">
                      {" "}
                      {t("Bahşiş Ekle :")}
                    </Text>
                    <NumberInput
                      borderColor="teal"
                      onChange={(valueString) => {
                        const value = parseInt(valueString);
                        if (isNaN(value)) {
                          setTip(0);
                        } else {
                          setTip(value);
                        }
                      }}
                      value={tip}
                      ml={2}
                      defaultValue={0.0}
                      min={0}
                      max={10000}
                    >
                      <NumberInputField width="80px" />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                  <CheckboxGroup>
                    <Button colorScheme="teal" variant="outline">
                      <Checkbox
                        borderColor="teal"
                        colorScheme="teal"
                        value={t("Nakit")}
                        onChange={handleCheckboxChange}
                      >
                        {t("Nakit")}
                      </Checkbox>
                    </Button>
                    &nbsp;
                    <Button colorScheme="teal" variant="outline">
                      <Checkbox
                        borderColor="teal"
                        colorScheme="teal"
                        value={t("Kredi Kartı")}
                        onChange={handleCheckboxChange}
                      >
                        {t("Kredi Kartı")}
                      </Checkbox>
                    </Button>
                    <Button colorScheme="teal" variant="outline">
                      <Checkbox
                        disabled
                        borderColor="teal"
                        colorScheme="teal"
                        value={t("Mobil")}
                        onChange={handleCheckboxChange}
                      >
                        {t("Mobil")}
                      </Checkbox>
                    </Button>
                  </CheckboxGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={accountPost}
                    bgGradient={theme.colors.ithinkGradient}
                    _hover={{ bgColor: theme.colors.ithinkGradient }}
                    background={theme.colors.ithinkGradient}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    color="white"
                  >
                    {t("Gönder")}
                  </Button>
                  &nbsp;
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h1
                            className="modal-title fs-5"
                            id="exampleModalLabel"
                          >
                            {t("Teşekkür Ederiz")}
                          </h1>
                        </div>
                        <div className="modal-body">
                          {t("Hesabınız Alınmıştır...")}
                        </div>
                        <div className="modal-footer">
                          <button
                            onClick={onClose}
                            type="button"
                            className="btn btn-success"
                            data-bs-dismiss="modal"
                          >
                            {t("Kapat")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button onClick={onClose}>{t("Kapat")}</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Button
              fontSize="xl"
              fontWeight="bold"
              height="50px"
              boxShadow="lg"
              width="35%"
              borderRadius={false}
              borderRightRadius={12}
              color="green.500"
              colorScheme="white"
            >
              {hesapToplam} ₺
            </Button>
          </Container>
        </Box>
        <NavbarBottom />
      </Box>
    </div>
  );
}
export default Account;
