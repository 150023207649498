import React, { useEffect, useState } from "react";
import NavbarBottom from "../layout/NavbarBottom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
} from "@chakra-ui/react";
import { useBasket } from "../store/BasketContext";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postOrder } from "../api/api";
import Navbar from "../layout/Navbar";
import { useSettings } from "../store/SettingsContext";

function OrderConfirm() {
  const { getLangText } = useSettings();
  const { setBasket, confirm, setConfirm } = useBasket();
  const { cancelTime } = useSettings();
  const [timer, setTimer] = useState();
  const { t } = useTranslation();
  const sendOrder = async () => {
    const basketProduct = confirm.map((item) => item);
    const input = {
      items: basketProduct,
    };
    await postOrder(input);
    setConfirm([]);
    setBasket([]);
  };

  useEffect(() => {
    if (cancelTime) {
      setTimer(cancelTime);
    }
  }, [cancelTime]);

  useEffect(() => {
    if (timer !== 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
    if (timer === 0) {
      sendOrder();
    }
  }, [timer]);

  return (
    <Box>
      <Navbar />
      <Link to="/">
        <Flex>
          <Box position="absolute" top="2" ml={2}>
            <i className="fas fa-angle-left yön"></i>
          </Box>
        </Flex>
      </Link>
      <Box mb={150}>
        {confirm.length < 1 && (
          <Container>
            <Alert
              mt={5}
              status="success"
              variant="solid"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon />
              {t("Siparişiniz Alınmıştır. Teşekkür ederiz...")}
            </Alert>
          </Container>
        )}

        {confirm.length > 0 && (
          <>
            <Box textAlign="center">
              <Box mt={5}>
                {timer > 0 && (
                  <Container>
                    <Alert
                      status="error"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <AlertIcon />

                      <AlertDescription>
                        {t("Siparişinizi")} <strong> {timer} </strong>{" "}
                        {t("saniye içinde iptal edebilirsiniz")}{" "}
                      </AlertDescription>
                    </Alert>
                  </Container>
                )}
              </Box>
            </Box>
            <br />
            {confirm.map((product, i) => {
              return (
                <Box key={i} mb={3} bgColor="white">
                  <Container>
                    <Grid
                      h="100%"
                      templateColumns="repeat(5, 1fr)"
                      gap={2}
                      width="100%"
                      bgColor="gray.50"
                      borderRadius={12}
                      key={product.id}
                      boxShadow="2xl"
                    >
                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box fontSize={16} color="teal" fontWeight="bold">
                          {getLangText(product.title)}
                        </Box>
                      </GridItem>

                      <GridItem w="100%" colSpan={1} mt={1} p={2}>
                        <Box
                          border="1px"
                          borderRadius={8}
                          color="teal"
                          textAlign="center"
                          fontSize={16}
                        >
                          <Text paddingX={0} color="teal">
                            x<strong> {product.count}</strong>
                          </Text>
                        </Box>
                      </GridItem>
                      <GridItem w="100%" mt={1} p={2} colSpan={2}>
                        <Box
                          borderRadius={10}
                          border="1px solid teal"
                          mx="auto"
                          width="100px"
                          textAlign="center"
                          color="teal"
                          fontSize={16}
                          fontWeight="bold"
                        >
                          {(product.count * product.price).toFixed(2)} ₺
                        </Box>
                      </GridItem>
                    </Grid>
                  </Container>
                </Box>
              );
            })}
          </>
        )}

        <Box
          position="fixed"
          bottom="12"
          width="100%"
          bgColor="white"
          boxShadow="lg"
          p={3}
        >
          <Container>
            <Link to="/basket">
              <Button
                disabled={confirm.length < 1 || timer === 0}
                onClick={() => setConfirm([])}
                _hover={{ bgColor: "red" }}
                background="red"
                color="white"
                height="50px"
                width="100%"
                bgColor="red"
              >
                {t("Sipariş İptal Et")}
              </Button>
            </Link>
          </Container>
        </Box>
      </Box>
      <NavbarBottom />
    </Box>
  );
}

export default OrderConfirm;
