import React, { useEffect, useState } from "react";
import { FormControl, Switch } from "@chakra-ui/react";
import { postSongStatus } from "../api/api";
import { useSettings } from "../store/SettingsContext";

const RequestSongSwitch = () => {
  const [songRequestStatusState, setSongRequestStatusState] = useState(0);
  const { songRequestStatus } = useSettings();

  const handleSwitchChange = async () => {
    try {
      await postSongStatus(songRequestStatusState);
      setSongRequestStatusState(songRequestStatusState);
    } catch (error) {
      console.error("Song request status güncelleme hatası:", error);
    }
  };

  useEffect(() => {
    setSongRequestStatusState(songRequestStatus);
  }, []);

  return (
    <FormControl display="flex" alignItems="center" justifyContent="flex-end">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: `2px solid ${songRequestStatusState ? "teal" : "red"}`,
          borderRadius: "18px",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            padding: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
          }}
        >
          <span style={{ margin: "0", fontSize: "20x" }}>
            {songRequestStatusState ? "İstek Açık" : "İstek Kapalı"}
          </span>
        </div>

        <Switch
          size="lg"
          colorScheme="teal"
          id="song-request-switch"
          onChange={handleSwitchChange}
          isChecked={songRequestStatusState}
          marginRight="5px"
        />
      </div>
    </FormControl>
  );
};

export default RequestSongSwitch;
