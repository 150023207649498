import React, { useState, createContext, useEffect, useContext } from "react";
import { accoundShow, settings } from "../api/api";
import Loading from "../components/Loading";
import { useAuth } from "./AuthContext";

const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const [cancelTime, setCancelTime] = useState(null);
  const [orderRequestStatus, setOrderRequestStatus] = useState(null);
  const [restaurantName, setRestaurantName] = useState("");
  const [songRequestStatus, setSongRequestStatus] = useState(null);
  const [waiterRequestStatus, setWaiterRequestStatus] = useState(null);
  const [instagramUrl, setInstagramUrl] = useState(null);
  const [mapUrl, setMapUrl] = useState("");
  const [whatsappPhone, setWhatsappPhone] = useState(null);
  const [instagramStatus, setInstagramStatus] = useState(null);
  const [whatsappStatus, setWhatsappStatus] = useState(null);
  const [googleMapsStatus, setGoogleMapsStatus] = useState(null);
  const [accountRequestStatus, setAccountRequestStatus] = useState(null);
  const [accountData, setAccountData] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [logo, setLogo] = useState(null);
  const { setLoggedIn } = useAuth();

  const [loading, setLoading] = useState(true);

  const loadData = (settings) => {
    setCancelTime(settings?.cancelTime);
    setOrderRequestStatus(settings?.orderRequest);
    setRestaurantName(settings?.restaurantName);
    setSongRequestStatus(settings?.songRequest);
    setWaiterRequestStatus(settings?.waiterRequest);
    setInstagramUrl(settings?.instagramUrl);
    setMapUrl(settings?.mapUrl);
    setWhatsappPhone(settings?.whatsappPhone);
    setInstagramStatus(settings?.instagram);
    setWhatsappStatus(settings?.whatsapp);
    setGoogleMapsStatus(settings?.googleMaps);
    setAccountRequestStatus(settings?.accountRequest);
    setOrderStatus(settings?.orderStatus);
    setReviewStatus(settings?.reviewStatus);
    setLogo(settings?.logo);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await settings();
        loadData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = await accoundShow();
        setAccountData(data);
        if (data) {
          setLoggedIn(true);
        }
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setLoggedIn(false);
        }
        setLoading(false);
      }
    })();
  }, []);

  const getLangText = (localizableObject) => {
    const lang = localStorage.getItem("LANG");

    if (lang) {
      switch (lang) {
        case "tr-TR":
          return localizableObject?.TRTR;
        case "en-US":
          if (localizableObject?.ENUS) {
            return localizableObject?.ENUS;
          } else {
            return localizableObject?.TRTR;
          }
        default:
          return localizableObject?.TRTR;
      }
    } else {
      return localizableObject?.TRTR;
    }
  };

  const values = {
    instagramUrl,
    mapUrl,
    whatsappPhone,
    instagramStatus,
    whatsappStatus,
    cancelTime,
    googleMapsStatus,
    accountRequestStatus,
    orderStatus,
    orderRequestStatus,
    restaurantName,
    songRequestStatus,
    waiterRequestStatus,
    accountData,
    reviewStatus,
    loading,
    getLangText,
    logo,
  };

  if (loading) {
    return <Loading height="100vh" />;
  }

  return (
    <SettingsContext.Provider value={values}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => useContext(SettingsContext);

export { SettingsProvider, useSettings };
